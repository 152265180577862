$colorVariables: (
  'primary': --ion-color-primary,
  'secondary': --ion-color-secondary,
  'danger': --ion-color-danger,
  'light': --ion-color-light,
  'dark': --ion-color-dark,
  'dark-tint': --ion-color-dark-tint,
  'success': --ion-color-success,
  'success-tint': --ion-color-success-tint
);

@mixin set-color($type) {
  $typeColor: map-get($colorVariables, $type);
  color: var($typeColor, color($colors, $type)) !important;
}

@mixin set-background-color($type) {
  $typeColor: map-get($colorVariables, $type);
  background-color: var($typeColor, color($colors, $type));
}

@mixin set-border($width, $type, $colorType, $radius) {
  $color: map-get($colorVariables, $colorType);
  border-width: $width + px;
  border-style: $type;
  border-color: var($color, color($colors, $type)) !important;

  @if $radius != 0 {
    border-radius: $radius + px;
  }
}

@mixin set-icon-color($type) {
  $typeColor: map-get($colorVariables, $type);
  fill: var($typeColor, color($colors, $type));
  stroke: var($typeColor, color($colors, $type));
}

@mixin set-ion-radio-color($type) {
  $typeColor: map-get($colorVariables, $type);
  --color-checked: #{var($typeColor, color($colors, $type))} !important;
}
