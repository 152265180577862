$font-path: '../assets/fonts/';

// Icomoon font imports
@font-face {
  font-family: 'icomoon';
  src: url('#{$font-path}icomoon/icomoon.eot?7re9t0');
  src:
    url('#{$font-path}icomoon/icomoon.eot?7re9t0#iefix') format('embedded-opentype'),
    url('#{$font-path}icomoon/icomoon.ttf?7re9t0') format('truetype'),
    url('#{$font-path}icomoon/icomoon.woff?7re9t0') format('woff'),
    url('#{$font-path}icomoon/icomoon.svg?7re9t0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'],
[class^='ion-ios-icon'],
[class*='ion-ios-icon'],
[class^='ion-md-icon'],
[class*='ion-md-icon'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  @extend .ion !optional;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin makeIcon($arg, $val) {
  .#{$arg}:before,
  .ion-ios-#{$arg}:before,
  .ion-ios-#{$arg}-circle:before,
  .ion-ios-#{$arg}-circle-outline:before,
  .ion-ios-#{$arg}-outline:before,
  .ion-md-#{$arg}:before,
  .ion-md-#{$arg}-circle:before,
  .ion-md-#{$arg}-circle-outline:before,
  .ion-md-#{$arg}-outline:before {
    content: $val;
  }
}

@include makeIcon(icon-atm-search, '\e900');
@include makeIcon(icon-camera-view, '\e901');
@include makeIcon(icon-home-search, '\e902');
@include makeIcon(icon-recent-history, '\e903');
@include makeIcon(icon-bathroom, '\e904');
@include makeIcon(icon-bedroom, '\e905');
@include makeIcon(icon-property-calculator, '\e906');
@include makeIcon(icon-fast-form, '\e907');
@include makeIcon(icon-order-title, '\e908');
@include makeIcon(icon-profile-pic-card-extended, '\e909'); // color: #d8d8d8;
@include makeIcon(icon-nav-calculator-active, '\e90a');
@include makeIcon(icon-nav-calculator-inactive, '\e90b');
@include makeIcon(icon-nav-more, '\e90c');
@include makeIcon(icon-nav-property-search-active, '\e90d');
@include makeIcon(icon-phone, '\e90e'); // color: #fff;
@include makeIcon(icon-email, '\e90f'); // color: #fff;
@include makeIcon(icon-more-search-options, '\e910');
@include makeIcon(icon-cross, '\e913');
@include makeIcon(icon-add-contact, '\e914'); // color: #fff;
@include makeIcon(icon-nav-property-search-inactive, '\e915');
@include makeIcon(icon-nav-walking-farm-active, '\e916');
@include makeIcon(icon-nav-walking-farm-inactive, '\e917');
@include makeIcon(icon-pf-back-arrow, '\e918');
@include makeIcon(icon-undo, '\e919');
@include makeIcon(icon-save-file, '\e91a'); // color: #fff;
@include makeIcon(icon-pencil, '\e91b'); // color: #fff;
@include makeIcon(icon-layers, '\e91c'); // color: #6e6f76;
@include makeIcon(icon-filter, '\e91d');
@include makeIcon(icon-delete, '\e91e');
@include makeIcon(icon-cross-rounded, '\e91f');
@include makeIcon(icon-check, '\e920'); // color: #fff;
@include makeIcon(icon-triangle, '\e921');
@include makeIcon(icon-info, '\e922');
@include makeIcon(icon-check2, '\e923'); // color: #484c5e;
@include makeIcon(icon-directions, '\e924');
@include makeIcon(icon-affordability-results, '\e925');
@include makeIcon(icon-payment-results, '\e926');
@include makeIcon(icon-eraser, '\e927');
@include makeIcon(icon-directions-new, '\e928');
@include makeIcon(icon-exports, '\e929');
@include makeIcon(icon-minus, '\e92a');
@include makeIcon(icon-notifications, '\e92b');
@include makeIcon(icon-plus, '\e92c');
@include makeIcon(icon-leader-status, '\e92d');
@include makeIcon(icon-pf-arrow, '\e92e');
@include makeIcon(icon-pf-arrow2, '\e92f');
@include makeIcon(icon-camera, '\e930');
@include makeIcon(icon-pool, '\e931');
@include makeIcon(icon-show-password, '\e932');
@include makeIcon(icon-help, '\e933');
@include makeIcon(icon-camera1, '\e934');
@include makeIcon(icon-pin, '\e935'); // color: #ee3c37
@include makeIcon(icon-advanced-search, '\e943');
@include makeIcon(icon-sms, '\e944');
@include makeIcon(icon-notification-off, '\e945');
@include makeIcon(icon-notification-on, '\e946');
@include makeIcon(icon-camera2, '\e947');

@mixin makeMultiPathIcon($arg, $val-list, $paths-num, $colors: null, $margin-left-list: null) {
  @for $i from 1 through $paths-num {
    .#{$arg} .path#{$i}:before,
    .ion-ios-#{$arg} .path#{$i}:before,
    .ion-ios-#{$arg}-circle .path#{$i}:before,
    .ion-ios-#{$arg}-circle-outline .path#{$i}:before,
    .ion-ios-#{$arg}-outline .path#{$i}:before,
    .ion-md-#{$arg} .path#{$i}:before,
    .ion-md-#{$arg}-circle .path#{$i}:before,
    .ion-md-#{$arg}-circle-outline .path#{$i}:before,
    .ion-md-#{$arg}-outline .path#{$i}:before {
      content: nth($val-list, $i);

      @if (($colors) & length($colors) > 0) {
        @if (nth($colors, $i)) {
          color: nth($colors, $i);
        }
      }

      @if (($margin-left-list) & length($margin-left-list) > 0) {
        @if (nth($margin-left-list, $i)) {
          margin-left: nth($margin-left-list, $i);
        }
      }
    }
  }
}

@include makeMultiPathIcon(
  icon-pin-leadstatus,
  '\e911' '\e912',
  2,
  rgb(255, 255, 255) rgb(151, 151, 151),
  null -0.599609375em
);
@include makeMultiPathIcon(
  icon-custom-pin,
  '\e937' '\e938',
  2,
  rgb(255, 255, 255) rgb(238, 60, 55),
  null -0.60546875em
);
@include makeMultiPathIcon(
  icon-pin-with-alert,
  '\e936' '\e939' '\e93a' '\e93b',
  4,
  rgb(255, 255, 255) rgb(93, 69, 173) rgb(93, 69, 173) rgb(171, 0, 21),
  null -0.7001953125em -0.7001953125em -0.7001953125em
);
@include makeMultiPathIcon(
  icon-pin-likely-to-sell,
  '\e93c' '\e93d' '\e93e',
  3,
  rgb(231, 110, 26) rgb(255, 255, 255) rgb(1, 134, 38),
  null -0.60546875em -0.60546875em
);
@include makeMultiPathIcon(
  icon-pin-likely-to-sell-with-alert,
  '\e93f' '\e940' '\e941' '\e942',
  4,
  rgb(231, 110, 26) rgb(255, 255, 255) rgb(238, 60, 55) rgb(171, 0, 21),
  null -0.7109375em -0.7109375em -0.7109375em
);

@font-face {
  font-family: 'san_francisco_display_bold';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-bold.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco_display_heavy';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-heavy.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco_display_light';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-light.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco_display_medium';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-medium.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco_display_regular';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-regular.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco_display_semibold';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-semibold.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco_display_thin';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-thin.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'san_francisco_display_ultralight';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-ultralight.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-ultralight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
