/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import './theme/variables.scss';
@import './theme/mixins.scss';
@import './theme/fonts.scss';
@import './theme/typography.scss';
@import './theme/lead-status.scss';

@import 'node_modules/@redshed/netsheet-calculator/styles/theme.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

ion-content::part(background) {
  background-color: transparent;
}

// Style that sets the right padding for an ion-item, unless a no-padding setting is required
// By default, ion-item does not have a padding-right when using inset (and it looks weird)
ion-item[lines='inset']:not(.ion-no-padding)::part(native) {
  padding-right: 16px;
}

ion-item::part(native) {
  background-color: transparent;
}
ion-item:not(.sc-ion-select-popover) {
  --padding-end: 0;
  --inner-padding-end: 0;
}

ion-button.tw-rounded-full::part(native) {
  border-radius: 9999px;
}

ion-select::part(icon) {
  margin-right: 5px;
}
:host(ion-select) {
  label {
    position: relative !important;
    width: 0 !important;
    height: 0 !important;
  }
}

ion-toggle.toggle-checked[color='success']::part(track) {
  @include set-background-color('success-tint');
}

form {
  ion-label {
    font-weight: bold;
  }
}

@media (max-width: 991px) {
  .gm-bundled-control {
    .gmnoprint {
      top: 3.5rem !important;
    }
  }
}

.pac-container {
  margin-top: -6px;
  box-shadow: none;

  .pac-item:first-of-type {
    border-top: none;
  }
}

.searchbar-input.sc-ion-searchbar-md,
.searchbar-input.sc-ion-searchbar-ios {
  background-color: #ffffff !important;
  font-size: 14px;
}
.filters-menu-active .searchbar-input.sc-ion-searchbar-ios {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.searchbar-search-icon.sc-ion-searchbar-md,
.searchbar-clear-button.sc-ion-searchbar-md,
.searchbar-search-icon.sc-ion-searchbar-ios,
.searchbar-clear-button.sc-ion-searchbar-ios {
  opacity: 0.65;
}
.searchbar-search-icon.sc-ion-searchbar-ios {
  font-size: 14px;
  height: 20px;
  width: 18px;
}

ion-searchbar.smd-mobile-ion-searchbar {
  .searchbar-search-icon.sc-ion-searchbar-ios {
    top: 0.5rem;
  }
}

.cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

ion-range::part(bar) {
  background-color: lightgray;
}

agm-map {
  .gm-style-iw-chr {
    position: absolute;
    right: 0.5rem;
    top: 0.25rem;

    button {
      height: auto !important;
      width: auto !important;
    }
    button span {
      height: 1rem !important;
      width: 1rem !important;
      margin: 0 !important;
    }
  }

  .gm-style-iw-ch {
    display: none;
  }
}

/* Custom smd- selectors start here */
smd-page-layout {
  background-color: #f2f2f2;
  height: 100%;
}

.smd-search-bar-item::part(native) {
  padding: 0;
}

.smd-map-layers-btn::part(native) {
  padding: 0;
}

.smd-svg-icon-color {
  @include set-icon-color('primary');
  stroke-width: 5px;
}

.smd-profile-link::part(native) {
  padding: 0;
}

.smd-dropdown-menu {
  margin: 0 2px;
}

.smd-page-content {
  height: 100%;
}

.smd-ipad-ion-buttons {
  bottom: 2.5rem !important;
}

.smd-primary-map-btn {
  height: 4rem !important;
  width: 4rem;

  ion-icon {
    font-size: 1.5rem;
  }
}

.smd-go-to-calculators {
  @include set-color('success');

  &:hover {
    @include set-color('secondary');
  }
}

.smd-alerts-count-badge {
  padding: 2px 3px;
  font-size: 12px;
}

// FIXME: Lead Status - Find a more elegant solution for this
.smd-lead-status-bullet {
  &.lead {
    background-color: status-color($status-colors, lead);
  }
  &.prospect {
    background-color: status-color($status-colors, prospect);
  }
  &.on-market {
    background-color: status-color($status-colors, onMarket);
  }
  &.not-interested {
    background-color: status-color($status-colors, notInterested);
  }
  &.not-home {
    background-color: status-color($status-colors, notHome);
  }
  &.not-contacted {
    background-color: status-color($status-colors, notContacted);
  }
  &.likely-to-sell {
    background-color: status-color($status-colors, likelyToSell);
  }
}

// FIXME: Lead Status - Find a more elegant solution for this
.smd-lead-status,
.smd-lead-status-select {
  &.lead {
    color: status-color($status-colors, lead);
    border: 1px solid status-color($status-colors, lead);
    background-color: status-color($status-colors, leadTint);
  }
  &.prospect {
    color: status-color($status-colors, prospect);
    border: 1px solid status-color($status-colors, prospect);
    background-color: status-color($status-colors, prospectTint);
  }
  &.on-market {
    color: status-color($status-colors, onMarket);
    border: 1px solid status-color($status-colors, onMarket);
    background-color: status-color($status-colors, onMarketTint);
  }
  &.not-interested {
    color: status-color($status-colors, notInterested);
    border: 1px solid status-color($status-colors, notInterested);
    background-color: status-color($status-colors, notInterestedTint);
  }
  &.not-home {
    color: status-color($status-colors, notHome);
    border: 1px solid status-color($status-colors, notHome);
    background-color: status-color($status-colors, notHomeTint);
  }
  &.not-contacted {
    color: status-color($status-colors, notContacted);
    border: 1px solid status-color($status-colors, notContacted);
    background-color: status-color($status-colors, notContactedTint);
  }
  &.likely-to-sell {
    color: status-color($status-colors, likelyToSell);
    border: 1px solid status-color($status-colors, likelyToSell);
    background-color: status-color($status-colors, likelyToSellTint);
  }
}

// FIXME: Lead Status - Find a more elegant solution for this
.lead {
  &.select-interface-option,
  .alert-radio-label.sc-ion-alert-md,
  .sc-ion-action-sheet-ios,
  .sc-ion-action-sheet-md {
    color: color($colors, success) !important;
  }
}
.prospect {
  &.select-interface-option,
  .alert-radio-label.sc-ion-alert-md,
  .sc-ion-action-sheet-ios,
  .sc-ion-action-sheet-md {
    color: status-color($status-colors, prospect) !important;
  }
}
.on-market {
  &.select-interface-option,
  .alert-radio-label.sc-ion-alert-md,
  .sc-ion-action-sheet-ios,
  .sc-ion-action-sheet-md {
    color: status-color($status-colors, onMarket) !important;
  }
}
.not-interested {
  &.select-interface-option,
  .alert-radio-label.sc-ion-alert-md,
  .sc-ion-action-sheet-ios,
  .sc-ion-action-sheet-md {
    color: status-color($status-colors, notInterested) !important;
  }
}
.not-home {
  &.select-interface-option,
  .alert-radio-label.sc-ion-alert-md,
  .sc-ion-action-sheet-ios,
  .sc-ion-action-sheet-md {
    color: status-color($status-colors, notHome) !important;
  }
}
.not-contacted {
  &.select-interface-option,
  .alert-radio-label.sc-ion-alert-md,
  .sc-ion-action-sheet-ios,
  .sc-ion-action-sheet-md {
    color: status-color($status-colors, notContacted) !important;
  }
}
.likely-to-sell {
  &.select-interface-option,
  .alert-radio-label.sc-ion-alert-md,
  .sc-ion-action-sheet-ios,
  .sc-ion-action-sheet-md {
    color: status-color($status-colors, likelyToSell) !important;
  }
}

.smd-radio-filters-list {
  background-color: transparent;

  ion-item {
    --background: transparent;
    --min-height: 36px;

    ion-radio {
      @include set-ion-radio-color('primary');
    }
  }
}

@media (min-width: 992px) {
  .sc-ion-input-md-h,
  .item-label-stacked.sc-ion-input-md-h,
  .item-label-stacked .sc-ion-input-md-h,
  .item-label-floating.sc-ion-input-md-h,
  .item-label-floating .sc-ion-input-md-h {
    --padding-top: 6px;
    --padding-bottom: 6px;
  }

  smd-page-layout {
    overflow: auto;
  }

  .searchbar-input-container {
    padding: 0 1px;
  }

  .searchbar-input.sc-ion-searchbar-md {
    border-radius: 10px !important;
    box-shadow: 0 0 3px #cccccc;
  }
  .filters-menu-active .searchbar-input.sc-ion-searchbar-md {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (max-width: 991px) {
  .action-sheet-group-cancel {
    border-top: 1px solid #888;
    background-color: #dedede;

    .action-sheet-button-inner {
      font-weight: bold;
    }
  }

  smd-page-layout {
    display: flex;
    flex-direction: column;
  }

  .smd-no-map-search-bar .searchbar-input.sc-ion-searchbar-ios {
    box-shadow: 0 0 3px #cccccc;
  }
}

// FIXME: Temporary styles for the TOS text
.section-title {
  @include set-color('dark-tint');
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  font-weight: bold;
}
.terms-description,
.terms-text,
.terms-list-text {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;
  padding-top: 10px;
  text-align: justify;
}
.terms-title {
  font-size: 20px;
  line-height: 24px;
  margin: 30px 0 10px;
}
.terms-section {
  padding-left: 10px;
  margin: 10px 0;

  .section-title {
    padding-top: 10px;
  }
}
.terms-list {
  padding-left: 20px;
  opacity: 0.75;
}

.smd-sub-description {
  ul {
    list-style-type: disc;
    padding-left: 2rem;
  }

  b {
    font-weight: 400;
  }

  i {
    font-style: italic;
  }
}

.smd-text-danger {
  @include set-color('danger');
}

ion-segment {
  border-radius: 0.25rem !important;

  ion-segment-button {
    border: none !important;
  }
}
