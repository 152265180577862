$font-path: '../assets/fonts/';

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-thin.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-ultralight.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-ultralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-light.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-regular.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-medium.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-semibold.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-bold.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'San Francisco Display';
  src:
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-heavy.woff2') format('woff2'),
    url('#{$font-path}SanFranciscoDisplay/sanfranciscodisplay-heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

:root {
  --ion-font-family: 'San Francisco Display';
}

* {
  font-family: 'San Francisco Display';
}
